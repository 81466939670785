import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import chroma from "chroma-js";
import SimplePagination from "~/components/SimplePagination";
import Button from "react-bootstrap/Button";
import ReactSelect from "react-select";
import {FormattedMessage} from "react-intl";
import styles from "./styles.module.scss";
import ProcessButton from "~/components/ProcessButton";
import config from "~/config";
import BackendSelection from "~/util/BackendSelection";

export default class MatchResultsPaginationBar extends React.PureComponent {
    static propTypes = {
        page: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        totalItems: PropTypes.number.isRequired,
        selection: PropTypes.array.isRequired,
        backendSelection: PropTypes.instanceOf(BackendSelection).isRequired,
        makingSelection: PropTypes.bool.isRequired,
        selectionStatuses: PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.any.isRequired,
                label: PropTypes.string.isRequired,
                color: PropTypes.string,
            })
        ).isRequired,
        selectionStatus: PropTypes.any,
        canDownload: PropTypes.bool.isRequired,
        canSelect: PropTypes.bool.isRequired,
        canCompare: PropTypes.bool.isRequired,
        selectLabel: PropTypes.string.isRequired,
        compareLabel: PropTypes.string.isRequired,
        onSelectionStatusChange: PropTypes.func.isRequired,
        onPageChange: PropTypes.func,
        onDownload: PropTypes.func,
        onSelect: PropTypes.func,
        onCompare: PropTypes.func,
        onSms: PropTypes.func,
    };

    static defaultProps = {
        canDownload: false,
        canSelect: false,
        canCompare: false,
        makingSelection: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            downloadingResults: false,
        };
    }

    render() {
        const {
            page,
            pageSize,
            totalItems,
            selection,
            backendSelection,
            makingSelection,
            canDownload,
            canSelect,
            canCompare,
            selectLabel,
            compareLabel,
            onPageChange,
            onSelect,
            onCompare,
        } = this.props;
        const {downloadingResults} = this.state;

        const rawSelectionSize = selection.length;
        const selectionSizeWithoutBackend = backendSelection.removeExistingSelectionFrom(selection).length;

        return (
            <div className={c("d-flex p-1", styles.main)}>
                {this.renderStatusSelect()}
                {canSelect && (
                    <ProcessButton
                        label={
                            <FormattedMessage id={selectLabel} values={{count: selectionSizeWithoutBackend}} />
                        }
                        className="mr-1"
                        size="sm"
                        onClick={onSelect}
                        processing={makingSelection}
                        disabled={selectionSizeWithoutBackend < 1}
                    />
                )}
                {canCompare && (
                    <Button
                        className="mr-1"
                        size="sm"
                        onClick={onCompare}
                        disabled={rawSelectionSize < 2}
                    >
                        <FormattedMessage id={compareLabel} values={{count: rawSelectionSize}} />
                    </Button>
                )}
                {canDownload && (
                    <ProcessButton
                        className="mr-1"
                        size="sm"
                        label={<FormattedMessage id="download.matchResults" />}
                        processing={downloadingResults}
                        onClick={this.handleDownload}
                    />
                )}
                <SimplePagination
                    className="ml-auto"
                    page={page}
                    itemsPerPage={pageSize}
                    totalItems={totalItems}
                    onChange={onPageChange}
                    radius={2}
                />
            </div>
        );
    }

    renderStatusSelect() {
        const {canSelect, selectionStatuses, selectionStatus} = this.props;
        if (
            !canSelect ||
            selectionStatuses.length === 0 ||
            config("ui.disableMatchStatusSelection")
        )
            return null;

        const selected = selectionStatuses.find(x => x.value === selectionStatus);

        return (
            <ReactSelect
                className={c("mr-1", styles.statusSelect)}
                styles={REACT_SELECT_STYLES}
                options={selectionStatuses}
                value={selected}
                menuPosition="fixed"
                onChange={this.handleStatusChange}
            />
        );
    }

    handleStatusChange = option => {
        const {onSelectionStatusChange} = this.props;
        onSelectionStatusChange(option.value);
    };

    handleDownload = () => {
        const {onDownload} = this.props;
        this.setState({downloadingResults: true});
        onDownload().then(() => {
            this.setState({downloadingResults: false});
        }).catch(e => {
            this.setState({downloadingResults: false});
            throw e;
        })
    }
}

const REACT_SELECT_STYLES = {
    control: provided => ({
        ...provided,
        minHeight: 29.5,
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: 3,
    }),
    clearIndicator: provided => ({
        ...provided,
        padding: 3,
    }),
    valueContainer: provided => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
    }),
    option: (provided, {data}) => {
        const color = chroma(data.color || "#FFFFFF");

        return {
            ...provided,
            backgroundColor: data.color,
            color: chroma.contrast(color, "white") > 2 ? "white" : "black",
            marginBottom: 1,
        };
    },
};
