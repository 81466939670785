import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import {FormattedMessage} from "react-intl";
import MatchComparisonTable from "~/components/MatchComparisonTable";
import styles from "./styles.module.scss";

export default class MatchComparisonDialog extends React.PureComponent {
    static propTypes = {
        matches: PropTypes.array, // TODO: More specific
        detailsTitleProperty: PropTypes.string,
        comparisonSubtitleProperty: PropTypes.string,
        titleLabel: PropTypes.string.isRequired,
        reverseLabel: PropTypes.string.isRequired,
        reverseHelp: PropTypes.string.isRequired,
        onHide: PropTypes.func.isRequired,
    };

    render() {
        const {
            matches,
            detailsTitleProperty,
            comparisonSubtitleProperty,
            titleLabel,
            reverseLabel,
            reverseHelp,
            onHide,
        } = this.props;

        return (
            <Modal size="xl" show={true} centered onHide={onHide} scrollable={true}>
                <Modal.Header closeButton={true}>
                    <Modal.Title>
                        <FormattedMessage id={titleLabel} values={{count: matches.length}} />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={styles.scrollContainer}>
                        <MatchComparisonTable
                            matches={matches}
                            detailsTitleProperty={detailsTitleProperty}
                            comparisonSubtitleProperty={comparisonSubtitleProperty}
                            reverseLabel={reverseLabel}
                            reverseHelp={reverseHelp}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
