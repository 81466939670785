import React from "react";
import PropTypes from "prop-types";
import Tab from "~/components/MatchingPage/ResultsTabBar/Tab";
import TabBar from "~/components/TabBar";
import {notDisabled} from "~/util/misc";

export default class ResultsTabBar extends React.PureComponent {
    static propTypes = {
        configuration: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        activeIndexName: PropTypes.string.isRequired,
        onActiveIndexNameChange: PropTypes.func.isRequired,
    };

    render() {
        const {configuration, state, activeIndexName} = this.props;

        return (
            <TabBar>
                {configuration.indices.filter(notDisabled).map((index, key) => {
                    return (
                        <Tab
                            key={key}
                            index={index}
                            state={state.results[index.name]}
                            isActive={index.name === activeIndexName}
                            onClick={this.handleTabClick}
                        />
                    );
                })}
                {configuration.placeholderTabs.map((placeholderTab, key) => {
                    return (
                        <Tab
                            key={key}
                            index={placeholderTab}
                            isActive={placeholderTab.name === activeIndexName}
                            onClick={this.handleTabClick}
                        />
                    );
                })}
            </TabBar>
        );
    }

    handleTabClick = index => {
        this.props.onActiveIndexNameChange(index.name);
    };
}
