import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import uuid from "uuid/v4";
import {createSelector} from "reselect";
import {injectIntl} from "react-intl";
import TranslatableLabelPropType from "~/prop-types/translatable-label";
import styles from "./styles.module.scss";
import {isString} from "~/util/misc";

class Select extends React.PureComponent {
    static OptionPropType = PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.oneOfType([
            TranslatableLabelPropType,
            PropTypes.arrayOf(TranslatableLabelPropType),
        ]).isRequired,
        disabled: PropTypes.bool,
    });

    static propTypes = {
        selected: PropTypes.oneOfType([Select.OptionPropType, PropTypes.any]),
        options: PropTypes.arrayOf(Select.OptionPropType).isRequired,
        variant: PropTypes.oneOf(["normal", "small", "extra-small"]),
        className: PropTypes.string,
        disabled: PropTypes.bool.isRequired,
        unstyled: PropTypes.bool.isRequired,
        onChange: PropTypes.func.isRequired,
        intl: PropTypes.object.isRequired,
    };

    static defaultProps = {
        variant: "normal",
        disabled: false,
        unstyled: false,
    };

    render() {
        const {options, className, variant, unstyled, disabled} = this.props;
        const optionsWithJson = optionsWithJsonSelector(options);

        const finalClassName = unstyled
            ? className
            : c("custom-select", styles.select, styles[variant], className);

        return (
            <select
                value={this.getSelectedJson()}
                onChange={this.handleChange}
                className={finalClassName}
                disabled={disabled}
            >
                {this.renderOptions(optionsWithJson)}
            </select>
        );
    }

    renderOptions(options) {
        return options.map(option => {
            if (option.options) {
                return (
                    <optgroup
                        key={option.id}
                        label={this.labelToString(option.label)}
                        style={option.style}
                        className={option.className}
                    >
                        {this.renderOptions(option.options)}
                    </optgroup>
                );
            } else {
                return (
                    <option
                        key={option.json}
                        value={option.json}
                        disabled={option.disabled}
                        style={option.style}
                        className={option.className}
                    >
                        {this.labelToString(option.label)}
                    </option>
                );
            }
        });
    }

    labelToString(label) {
        if (Array.isArray(label)) {
            return label.map(this.singleLabelValueToString).join("");
        } else {
            return this.singleLabelValueToString(label);
        }
    }

    singleLabelValueToString = value => {
        if (isString(value)) {
            return value;
        } else {
            return this.props.intl.formatMessage(value);
        }
    };

    getSelectedJson() {
        const {selected} = this.props;

        if (selected !== undefined) {
            if (selected !== null && selected.value) {
                return JSON.stringify(selected.value);
            } else {
                return JSON.stringify(selected);
            }
        } else {
            return undefined;
        }
    }

    handleChange = e => {
        const {options, onChange} = this.props;
        const optionsWithJson = optionsWithJsonSelector(options);
        const selectedJson = e.target.value;
        const originalOption = findOriginalOptionWithJson(optionsWithJson, selectedJson);

        if (originalOption !== undefined) {
            onChange(originalOption);
        }
    };
}

const optionsWithJsonSelector = createSelector(options => options, addJsonToOptions);

function addJsonToOptions(options) {
    return options.map(option => {
        if (option.options) {
            return {
                ...option,
                id: uuid(),
                options: addJsonToOptions(option.options),
            };
        } else {
            return addJsonToOption(option);
        }
    });
}

function addJsonToOption(option) {
    return {
        ...option,
        json: JSON.stringify(option.value),
        originalOption: option,
    };
}

function findOriginalOptionWithJson(options, json) {
    for (const option of options) {
        if (option.options) {
            const originalOption = findOriginalOptionWithJson(option.options, json);
            if (originalOption !== undefined) return originalOption;
        } else if (option.json === json) {
            return option.originalOption;
        }
    }
}

export default injectIntl(Select, {forwardRef: true});
