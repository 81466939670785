import {connect} from "react-redux";
import MatchBlock from "~/components/MatchingPage/MatchBlock";

function mapStateToProps(state) {
    return {
        //
    };
}

const mapDispatchToProps = {
    //
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchBlock);
