import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import {injectIntl} from "react-intl";
import Select from "~/components/Select";
import MultiSelect from "react-select";
import PredicateEditorPropTypes from "../../prop-types";
import styles from "./styles.module.scss";
import {loadEnumValues} from "~/util/filter-values";

const COMPARISONS = {
    isOneOf: {
        label: {id: "predicateEditor.isOneOf"},
        hasInput: true,
        toFilter: (property, input) => {
            return {
                terms: {[property]: input},
            };
        },
    },
    isNotOneOf: {
        label: {id: "predicateEditor.isNotOneOf"},
        hasInput: true,
        toFilter: (property, input) => {
            return {
                bool: {
                    must_not: {
                        terms: {[property]: input},
                    },
                },
            };
        },
    },
    isEmpty: {
        label: {id: "predicateEditor.isEmpty"},
        hasInput: false,
        toFilter: property => ({bool: {must_not: {exists: {field: property}}}}),
    },
    isNotEmpty: {
        label: {id: "predicateEditor.isNotEmpty"},
        hasInput: false,
        toFilter: property => ({bool: {must: {exists: {field: property}}}}),
    },
};

const DataPropType = PropTypes.shape({
    comparison: PropTypes.oneOf(Object.keys(COMPARISONS)),
    input: PropTypes.arrayOf(PropTypes.string).isRequired,
});

class EnumProperty extends React.PureComponent {
    static propTypes = {
        data: DataPropType.isRequired,
        propertyDefinition: PredicateEditorPropTypes.propertyDefinition.isRequired,
        overlappingData: PropTypes.arrayOf(DataPropType).isRequired,
        onChange: PropTypes.func.isRequired,
        intl: PropTypes.object.isRequired,
    };

    static defaultValue() {
        return {
            comparison: "isOneOf",
            input: [],
        };
    }

    static toFilter(property, data) {
        const comparisonInfo = COMPARISONS[data.comparison];

        if (data.input.length > 0 || !comparisonInfo.hasInput) {
            return comparisonInfo.toFilter(property, data.input);
        } else {
            return undefined;
        }
    }

    constructor(props) {
        super(props);

        this.state = {
            rawValues: undefined,
            options: [],
        };

        this.unmounted = false;
    }

    componentDidMount() {
        this.updateOptionsIfNeeded();
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateOptionsIfNeeded();
    }

    updateOptionsIfNeeded() {
        const {propertyDefinition, intl} = this.props;

        if (this.state.rawValues !== propertyDefinition.values) {
            this.setState({rawValues: propertyDefinition.values}, () => {
                loadEnumValues(propertyDefinition.values, intl).then(options => {
                    if (this.unmounted) {
                        return;
                    }

                    if (this.state.rawValues === propertyDefinition.values) {
                        this.setState({options});
                    }
                });
            });
        }
    }

    render() {
        const {data} = this.props;
        const {options} = this.state;
        const comparisonInfo = COMPARISONS[data.comparison];
        const unavailableValues = this.getUnavailableValues();

        return (
            <React.Fragment>
                <Select
                    className={c(styles.givePriority, styles.comparisonSelect)}
                    selected={data.comparison}
                    onChange={this.handleComparisonChange}
                    options={COMPARISON_OPTIONS}
                    variant="extra-small"
                />
                {comparisonInfo.hasInput && (
                    <MultiSelect
                        className={c(styles.givePriority, styles.select)}
                        styles={MULTI_SELECT_STYLES}
                        options={options.filter(
                            option => !unavailableValues.includes(option.value)
                        )}
                        isMulti={true}
                        menuPosition="fixed"
                        value={data.input.map(value => toOption(options, value))}
                        onChange={this.handleInputChange}
                    />
                )}
            </React.Fragment>
        );
    }

    getUnavailableValues() {
        const {data, overlappingData} = this.props;
        let oppositeComparison;

        if (data.comparison === "isOneOf") {
            oppositeComparison = "isNotOneOf";
        } else if (data.comparison === "isNotOneOf") {
            oppositeComparison = "isOneOf";
        } else {
            return [];
        }

        return overlappingData
            .filter(data => data.comparison === oppositeComparison)
            .reduce((values, data) => {
                values.push(...data.input);
                return values;
            }, []);
    }

    handleComparisonChange = option => {
        const {data, onChange} = this.props;
        const nextData = {...data, comparison: option.value};
        onChange(nextData);
    };

    handleInputChange = input => {
        const {data, onChange} = this.props;
        const nextData = {...data, input: input !== null ? input.map(x => x.value) : []};
        onChange(nextData);
    };
}

const COMPARISON_OPTIONS = Object.keys(COMPARISONS).map(key => ({
    label: COMPARISONS[key].label,
    value: key,
}));

function toOption(options, value) {
    const option = options.find(o => o.value === value);
    return {value, label: option ? option.label : value};
}

const MULTI_SELECT_STYLES = {
    control: provided => ({
        ...provided,
        minHeight: 29.5,
    }),
    dropdownIndicator: provided => ({
        ...provided,
        padding: 3,
    }),
    clearIndicator: provided => ({
        ...provided,
        padding: 3,
    }),
    valueContainer: provided => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
    }),
};

export default injectIntl(EnumProperty, {forwardRef: true});
