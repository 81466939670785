import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import TabBar from "~/components/TabBar";
import IndexTab from "~/components/MatchingPage/Filters/IndexTab";
import FiltersForIndex from "~/components/MatchingPage/Filters/FiltersForIndex";
import {notDisabled} from "~/util/misc";

export default class Filters extends React.PureComponent {
    static propTypes = {
        configuration: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        activeIndexName: PropTypes.string.isRequired,
        onCustomFiltersPredicateChange: PropTypes.func.isRequired,
        onFiltersChange: PropTypes.func.isRequired,
        onActiveIndexNameChange: PropTypes.func.isRequired,
    };

    render() {
        const {configuration} = this.props;

        if (!filtersAvailable(configuration)) {
            return null;
        }

        return (
            <React.Fragment>
                <div className={styles.header}>
                    <div className="ml-2 mr-2">Filters</div>
                    {this.renderTabBar()}
                </div>
                {this.renderFilters()}
            </React.Fragment>
        );
    }

    renderTabBar() {
        const {configuration, activeIndexName} = this.props;

        return (
            <TabBar>
                {configuration.indices.filter(notDisabled).map((index, key) => {
                    return filtersAvailableForIndex(index) ? (
                        <IndexTab
                            key={key}
                            index={index}
                            isActive={index.name === activeIndexName}
                            onClick={this.handleTabClick}
                        />
                    ) : null;
                })}
            </TabBar>
        );
    }

    renderFilters() {
        const {
            configuration,
            state,
            activeIndexName,
            onCustomFiltersPredicateChange,
            onFiltersChange,
        } = this.props;

        if (!activeIndexName) {
            return null;
        }

        const index = configuration.indices.filter(notDisabled).find(i => i.name === activeIndexName);

        if (!index) {
            return null;
        }

        const indexState = state.results[activeIndexName];

        return (
            <FiltersForIndex
                index={index}
                state={indexState}
                onCustomFiltersPredicateChange={onCustomFiltersPredicateChange}
                onFiltersChange={onFiltersChange}
            />
        );
    }

    handleTabClick = index => {
        const {onActiveIndexNameChange} = this.props;
        onActiveIndexNameChange(index.name);
    };
}

function filtersAvailable(configuration) {
    for (const index of configuration.indices) {
        if (filtersAvailableForIndex(index) && !index.disabled) {
            return true;
        }
    }

    return false;
}

function filtersAvailableForIndex(indexConfiguration) {
    return (
        indexConfiguration.customFiltersEnabled ||
        (Array.isArray(indexConfiguration.filters) && indexConfiguration.filters.length > 0)
    );
}
