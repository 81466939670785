import React from "react";
import PropTypes from "prop-types";
import {FormattedMessage} from "react-intl";
import objectPath from "object-path";
import MatchList from "~/components/MatchList";
import MatchActions from "./MatchActions";
import Button from "react-bootstrap/Button";
import config from "~/config";
import PhoneNumber from "~/components/PhoneNumber";

export default class CandidateMatchList extends React.PureComponent {
    static propTypes = {
        ...MatchList.propTypes,
        indexName: PropTypes.string.isRequired,
        extraPropertyPhoneNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
        onMatch: PropTypes.func,
        onVisitExternal: PropTypes.func,

        // Disable some things from MatchList we provide ourselves
        renderDetailsColumnHeader: PropTypes.any,
        renderDetailsColumn: PropTypes.any,
        renderMatchActions: PropTypes.any,
        extraColumnPropertyRoot: PropTypes.any,
    };

    static defaultProps = {
        annotations: {},
        allowSelection: false,
    };

    constructor(props) {
        super(props);

        this.mainRef = React.createRef();
    }

    scrollToTop() {
        this.mainRef.current.scrollToTop();
    }

    render() {
        const {
            indexName,
            extraPropertyPhoneNumbers,
            onMatch,
            onVisitExternal,
            ...matchingListProperties
        } = this.props;

        return (
            <MatchList
                ref={this.mainRef}
                {...matchingListProperties}
                renderDetailsColumnHeader={renderDetailsColumnHeader}
                renderDetailsColumn={renderDetailsColumn}
                renderMatchActions={this.renderMatchActions}
            />
        );
    }

    renderMatchActions = match => {
        const {indexName, onMatch, onVisitExternal} = this.props;

        return (
            <React.Fragment>
                {!!onMatch && (
                    <MatchActions indexName={indexName} match={match} onMatch={onMatch} />
                )}
                {this.renderPhoneButtons(match)}
                {!!onVisitExternal && (
                    <Button
                        size="sm"
                        variant="info"
                        onClick={this.handleVisitExternal.bind(this, match)}
                        className="ml-1"
                    >
                        <i className="fas fa-external-link-alt" />
                    </Button>
                )}
            </React.Fragment>
        );
    };

    renderPhoneButtons(match) {
        const {extraPropertyPhoneNumbers} = this.props;
        if (!extraPropertyPhoneNumbers || !config("ui.phoneButtonsInResultList")) {
            return;
        }

        const extraProperties = match.document.extraProperties;
        let phoneNumber = undefined;

        for (const property of extraPropertyPhoneNumbers) {
            phoneNumber = objectPath.get(extraProperties, property);

            if (phoneNumber !== undefined) {
                break;
            }
        }

        if (phoneNumber === undefined) {
            return;
        }

        return <PhoneNumber value={phoneNumber} onlyButtons={true} />;
    }

    handleVisitExternal = (match, e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.onVisitExternal(match.id);
    };
}

function renderDetailsColumnHeader() {
    return <FormattedMessage id="column.jobFunction" />;
}

function renderDetailsColumn(match) {
    return getJobFunctionLabel(match.candidate.matchProfile.aspects.jobFunctions);
}

export function getJobFunctionLabel(jobFunctions) {
    if (jobFunctions === undefined || jobFunctions.value === undefined) {
        return "—";
    }

    if (config("ui.displayAllFunctions")) {
        return (
            jobFunctions.value
                .filter(x => x && x.label)
                .map(x => x.label)
                .join(", ") || "—"
        );
    } else {
        const jobFunction = jobFunctions.value[0];

        if (jobFunction === undefined || jobFunction.label === undefined) {
            return "—";
        }

        return jobFunction.label;
    }
}
