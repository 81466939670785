import config from "~/config";
import objectPath from "object-path";
import {getSortMode} from "~/util/sort-config";
import {predicateToFilter, removeInvalidRules} from "~/components/PredicateEditor/util";

export function convertMatchRequestToApi(matchRequest, indexName, language, sortModeGroup) {
    const result = {
        matchProfile: convertMatchProfileToApi(matchRequest.matchProfile, language),
    };

    // Try custom filters
    const propertyDefinitions = config(`customFilters.${indexName}`, undefined);
    if (matchRequest.customFiltersPredicate !== undefined && propertyDefinitions !== undefined) {
        const matchFilter = predicateToFilter(
            removeInvalidRules(matchRequest.customFiltersPredicate, propertyDefinitions),
            propertyDefinitions
        );

        if (matchFilter) result.matchFilter = matchFilter;
    }

    // Try regular filters
    if (result.matchFilter === undefined) {
        const matchFilter = convertFiltersToApi(matchRequest.filters);
        if (matchFilter) result.matchFilter = matchFilter;
    }

    // Add excluded ID filter
    if (matchRequest.excludedIds && matchRequest.excludedIds.length > 0) {
        const matchFilter = {
            bool: {
                must_not: [
                    {terms: {_id: matchRequest.excludedIds}}
                ]
            }
        }

        if (result.matchFilter !== undefined) {
            matchFilter.bool.must = [result.matchFilter];
        }

        result.matchFilter = matchFilter;
    }

    if (sortModeGroup) {
        const sortMode = getSortMode(sortModeGroup, matchRequest.sortMode);
        if (sortMode) result.sort = sortMode.sort;
    }

    if (language && result.matchProfile.language === undefined) {
        result.matchProfile.language = language;
    }

    return result;
}

export function convertMatchRequestToApiV2(matchRequest, indexName, language, sortModeGroup) {
    // TODO: Proper implementation
    const request = convertMatchRequestToApi(matchRequest, indexName, language, sortModeGroup);
    delete request.matchProfile.language;
    return request;
}

export function convertMatchProfileToApi(matchProfile, language) {
    const result = {};

    for (const name in matchProfile.aspects) {
        let aspect = matchProfile.aspects[name];
        const aspectConfig = config(`aspects.${name}`);

        let converted = convertAspectToApi(aspect, language);

        if (converted === null) {
            continue;
        }

        if (aspectConfig.temporarySendAsProfileAspectFix === true) {
            // DIRTY FIX FOR AMSTERDAM, DO NOT USE!
            converted = {
                ...converted,
                profileWeight: converted.weight,
                profileRequirement: converted.required ? "REQUIRED" : "OPTIONAL",
                profileValue: converted.value.map(singleValue => ({
                    required: singleValue.required,
                    term: singleValue.value,
                    weight: singleValue.weight,
                    level: -1.0,
                })),
            };
            delete converted.weight;
            delete converted.required;
            delete converted.value;
        }

        if (aspectConfig.customPath) {
            const path = aspectConfig.customPath || `extraPropertes.${name}`;
            objectPath.set(result, path, converted);
        } else {
            result[name] = converted;
        }
    }

    return result;
}

export function convertAspectToApi(aspect, language) {
    switch (aspect.type) {
        case "concepts":
            return convertConceptsAspect(aspect, language);

        case "keywords":
            return convertKeywordsAspect(aspect);

        case "locations":
            return convertLocationsAspect(aspect);

        case "range":
            return convertRangeAspect(aspect);

        case "integer":
            return convertIntegerAspect(aspect);

        case "float":
            return convertFloatAspect(aspect);

        default:
            return null;
    }
}

function convertConceptsAspect(aspect, language) {
    if (aspect.value.length === 0) {
        return null;
    }

    return {
        weight: aspect.weight / config("ui.weight.scale"),
        required: aspect.required,
        value: aspect.value.map(value => ({
            mainLanguage: language,
            weight: value.weight / config("ui.weight.scale"),
            required: value.required,
            labels: [value.label],
        })),
    };
}

function convertKeywordsAspect(aspect) {
    if (aspect.value.length === 0) {
        return null;
    }

    return {
        weight: aspect.weight / config("ui.weight.scale"),
        required: aspect.required,
        value: aspect.value.map(value => ({
            weight: value.weight / config("ui.weight.scale"),
            required: value.required,
            value: value.label,
        })),
    };
}

function convertLocationsAspect(aspect) {
    if (aspect.value.length === 0) {
        return null;
    }

    return {
        weight: aspect.weight / config("ui.weight.scale"),
        required: aspect.required,
        value: aspect.value.map(value => ({
            weight: value.weight / config("ui.weight.scale"),
            required: value.required,
            label: value.label,
            range: value.range,
            rangeUnit: value.rangeUnit,
        })),
    };
}

function convertRangeAspect(aspect) {
    if (aspect.value === null) {
        return null;
    }

    return {
        weight: aspect.weight / config("ui.weight.scale"),
        required: aspect.required,
        value: {
            weight: aspect.value.weight / config("ui.weight.scale"),
            required: aspect.value.required,
            from: aspect.value.from,
            to: aspect.value.to,
        },
    };
}

function convertIntegerAspect(aspect) {
    if (aspect.value === null) {
        return null;
    }

    let number = aspect.value.value;
    if (!Number.isInteger(number)) {
        number = Math.round(number);
    }

    return {
        weight: aspect.weight / config("ui.weight.scale"),
        required: aspect.required,
        value: {
            weight: aspect.value.weight / config("ui.weight.scale"),
            required: aspect.value.required,
            value: number,
        },
    };
}

function convertFloatAspect(aspect) {
    if (aspect.value === null) {
        return null;
    }

    let number = aspect.value.value;
    if (Number.isInteger(number)) {
        // Pass integers as a string with decimals
        number = Number(number).toFixed(1);
    }

    return {
        weight: aspect.weight / config("ui.weight.scale"),
        required: aspect.required,
        value: {
            weight: aspect.value.weight / config("ui.weight.scale"),
            required: aspect.value.required,
            value: number,
        },
    };
}

function convertWorkExperience(workExperience) {
    return {
        title: workExperience.title,
        company: workExperience.company,
        text: workExperience.text,
        dateFrom: workExperience.dateFrom,
        dateTo: workExperience.dateTo,
    };
}

function convertEducation(education) {
    return {
        field: education.field,
        degree: education.degree,
        institute: education.institute,
        info: education.info,
        dateFrom: education.dateFrom,
        dateTo: education.dateTo,
    };
}

function convertFiltersToApi(filters) {
    const queries = [];

    for (const filterName in filters) {
        const filterData = filters[filterName];
        if (!filterData.enabled) continue;

        const filterConfig = config(`filters.${filterName}`);
        if (!filterConfig) continue;

        const property = config(`filters.${filterName}.property`);

        switch (filterConfig.type) {
            case "term":
            case "autocompleteTerm":
                if (typeof filterData.value === "object" && filterData.value !== null) {
                    // Value is a full query, so add it as is
                    queries.push(filterData.value);
                } else {
                    queries.push({term: {[property]: filterData.value}});
                }

                break;

            case "multipleTerm":
                queries.push({
                    bool: {should: filterData.value.map(value => ({term: {[property]: value}}))},
                });
                break;

            case "numberComparison":
                queries.push({
                    range: {
                        [property]: {[filterData.value.comparison]: filterData.value.comparand},
                    },
                });
                break;

            case "age":
                const comparison = getAgeComparison(filterData.value.comparison);
                const comparand = getAgeComparand(
                    filterData.value.comparison,
                    filterData.value.comparand
                );
                queries.push({range: {[property]: {[comparison]: comparand}}});
                break;

            default:
                break;
        }
    }

    if (queries.length > 0) {
        return {bool: {must: queries}};
    } else {
        return undefined;
    }
}

function getAgeComparison(comparison) {
    switch (comparison) {
        default:
        case "lt":
            return "gte";
        case "lte":
            return "gte";
        case "gte":
            return "lte";
        case "gt":
            return "lte";
    }
}

function getAgeComparand(comparison, comparand) {
    switch (comparison) {
        default:
        case "lt":
            return `now-${comparand}y/d`;
        case "lte":
            return `now-${comparand + 1}y/d`;
        case "gte":
            return `now-${comparand}y/d`;
        case "gt":
            return `now-${comparand + 1}y/d`;
    }
}
