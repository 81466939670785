import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.module.scss";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import uuid from "uuid/v4";
import Api from "~/api";
import {injectIntl} from "react-intl";
import MatchBlock from "~/containers/components/MatchBlock";

class KeywordSearch extends React.PureComponent {
    static propTypes = {
        keywordQuery: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
        withMatchBlock: PropTypes.bool.isRequired,
        matchRequestHasChanged: PropTypes.bool.isRequired,
        matchSourceEntity: PropTypes.object,
        onKeywordQueryChange: PropTypes.func.isRequired,
        onMatch: PropTypes.func.isRequired,
        onLanguageChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            options: [],
        };

        this.id = uuid();
        this.autoCompleteCall = null;
    }

    render() {
        const {
            intl,
            keywordQuery,
            withMatchBlock,
            matchRequestHasChanged,
            language,
            matchSourceEntity,
            onLanguageChange,
        } = this.props;
        const {isLoading, options} = this.state;

        return (
            <div className={styles.main}>
                <AsyncTypeahead
                    id={this.id}
                    className={styles.input}
                    selected={keywordQuery ? [keywordQuery] : []}
                    placeholder={intl.formatMessage({id: "keywordInput.placeholder"})}
                    isLoading={isLoading}
                    options={options}
                    minLength={2}
                    useCache={false}
                    onChange={this.handleChange}
                    onInputChange={this.handleInputChange}
                    onKeyDown={this.handleKeyDown}
                    onSearch={this.handleSearch}
                    bsSize="sm"
                />
                {withMatchBlock && (
                    <MatchBlock
                        className={styles.matchBlock}
                        language={language}
                        matchRequestHasChanged={matchRequestHasChanged || !!keywordQuery}
                        matchActionLabel={matchSourceEntity ? "action.match" : "action.search"}
                        onLanguageChange={onLanguageChange}
                        onMatch={this.handleMatch}
                    />
                )}
            </div>
        );
    }

    handleSearch = input => {
        const {language} = this.props;

        if (this.autoCompleteCall !== null) {
            this.autoCompleteCall.abort();
        }

        this.setState({isLoading: true});

        this.autoCompleteCall = Api.findPrefixStrings(input, language);

        this.autoCompleteCall.then(options => {
            this.setState({options, isLoading: false});
        });
    };

    handleKeyDown = e => {
        const {disabled, onMatch} = this.props;

        if (e.key === "Enter") {
            e.preventDefault();

            if (!disabled) {
                setTimeout(() => {
                    onMatch(true);
                }, 1);
            }
        }
    };

    handleMatch = () => {
        this.props.onMatch(true);
    };

    handleChange = ([value]) => {
        if (value !== undefined) {
            this.props.onKeywordQueryChange(value);
        }
    };

    handleInputChange = value => {
        this.props.onKeywordQueryChange(value);
    };
}

export default injectIntl(KeywordSearch, {forwardRef: true});
