import React from "react";
import PropTypes from "prop-types";
import equal from "deep-equal";
import ObjectPath from "object-path";
import c from "classnames";
import BasicPageLayout from "~/components/BasicPageLayout";
import KeywordSearch from "./KeywordSearch";
import MatchProfile from "./MatchProfile";
import Results from "./Results";
import styles from "./styles.module.scss";
import config from "~/config";
import Alert from "react-bootstrap/Alert";
import {FormattedMessage} from "react-intl";

export default class Layout extends React.PureComponent {
    static propTypes = {
        configuration: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        additionalMenuContent: PropTypes.node,
        language: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        showResetButton: PropTypes.bool.isRequired,
        updateMatchForIndex: PropTypes.func.isRequired,
        requestPage: PropTypes.func.isRequired,
        makeSelection: PropTypes.func.isRequired,
        selectAllFromApi: PropTypes.func.isRequired,
        onKeywordQueryChange: PropTypes.func,
        onMatchProfileChange: PropTypes.func.isRequired,
        onFiltersChange: PropTypes.func.isRequired,
        onMatch: PropTypes.func.isRequired,
        onClear: PropTypes.func.isRequired,
        onReset: PropTypes.func,
        onSortModeChange: PropTypes.func.isRequired,
        onLanguageChange: PropTypes.func.isRequired,
        onCustomFiltersPredicateChange: PropTypes.func.isRequired,
        onSelectionChange: PropTypes.func.isRequired,
        onSelectionStatusChange: PropTypes.func.isRequired,
        onUiStateUpdate: PropTypes.func.isRequired,
        onDownload: PropTypes.func.isRequired,
        onMatchToOthers: PropTypes.func.isRequired,
    };

    static defaultProps = {
        showResetButton: false,
        onMatchToOthers: () => {},
    };

    constructor(props) {
        super(props);

        this.state = {
            activeIndexName: ObjectPath.get(props, "configuration.indices.0.name", undefined),
        };

        this.matchProfileEditor = React.createRef();
    }

    updateSections(sections) {
        const {state, onUiStateUpdate} = this.props;

        onUiStateUpdate({
            sections: {
                ...state.ui.sections,
                ...sections,
            },
        });
    }

    openMatchProfileTab() {
        this.updateSections({
            [Sections.MATCH_PROFILE]: true,
            [Sections.RESULTS]: false,
        });
    }

    toggleMatchProfileTab(displayed) {
        this.updateSections({
            [Sections.MATCH_PROFILE]: displayed,
            [Sections.RESULTS]: !displayed,
        });
    }

    openResultsTab() {
        this.updateSections({
            [Sections.MATCH_PROFILE]: false,
            [Sections.RESULTS]: true,
        });
    }

    toggleResultsTab(displayed) {
        this.updateSections({
            [Sections.MATCH_PROFILE]: !displayed,
            [Sections.RESULTS]: displayed,
        });
    }

    resetMatchProfileEditor() {
        this.matchProfileEditor.current.reset();
    }

    render() {
        const {
            state,
            configuration,
            additionalMenuContent,
            language,
            isLoading,
            showResetButton,
            updateMatchForIndex,
            requestPage,
            makeSelection,
            selectAllFromApi,
            onKeywordQueryChange,
            onMatchProfileChange,
            onMatch,
            onClear,
            onReset,
            onFiltersChange,
            onSortModeChange,
            onLanguageChange,
            onCustomFiltersPredicateChange,
            onSelectionChange,
            onSelectionStatusChange,
            onDownload,
            onMatchToOthers,
        } = this.props;
        const {activeIndexName} = this.state;

        const removePagePadding = config("ui.removePagePadding");

        return (
            <BasicPageLayout additionalMenuContent={additionalMenuContent} isLoading={isLoading}>
                <div className={c(styles.page, {removePagePadding})}>
                    {config("ui.displayNotFullyIndexed", false) && (
                        <Alert variant="warning">
                            <FormattedMessage id="notFullyIndexedWarning" />
                        </Alert>
                    )}
                    {configuration.keywordSearch && (
                        <KeywordSearch
                            keywordQuery={state.query}
                            withMatchBlock={true}
                            language={language}
                            matchRequestHasChanged={this.matchRequestHasChanged()}
                            matchSourceEntity={state.matchSourceEntity}
                            onKeywordQueryChange={onKeywordQueryChange}
                            onMatch={onMatch}
                            onLanguageChange={onLanguageChange}
                        />
                    )}
                    <MatchProfile
                        configuration={configuration}
                        state={state}
                        activeIndexName={activeIndexName}
                        matchProfile={state.nextRequest.matchProfile}
                        indexName={configuration.indices[0].name}
                        language={language}
                        matchRequestHasChanged={this.matchRequestHasChanged()}
                        expanded={state.ui.sections[Sections.MATCH_PROFILE]}
                        withMatchBlock={!configuration.keywordSearch}
                        showResetButton={showResetButton}
                        showSuggestions={configuration.keywordSuggestions}
                        onToggleExpanded={this.handleToggleMatchProfileExpanded}
                        onMatchProfileChange={onMatchProfileChange}
                        onFiltersChange={onFiltersChange}
                        onMatch={onMatch}
                        onClear={onClear}
                        onReset={onReset}
                        onLanguageChange={onLanguageChange}
                        onCustomFiltersPredicateChange={onCustomFiltersPredicateChange}
                        matchProfileEditorRef={this.matchProfileEditor}
                        onActiveIndexNameChange={this.handleActiveIndexNameChange}
                    />
                    <Results
                        configuration={configuration}
                        state={state}
                        expanded={state.ui.sections[Sections.RESULTS]}
                        activeIndexName={activeIndexName}
                        updateMatchForIndex={updateMatchForIndex}
                        requestPage={requestPage}
                        makeSelection={makeSelection}
                        selectAllFromApi={selectAllFromApi}
                        onToggleExpanded={this.handleToggleResultsExpanded}
                        onSortModeChange={onSortModeChange}
                        onSelectionChange={onSelectionChange}
                        onSelectionStatusChange={onSelectionStatusChange}
                        onDownload={onDownload}
                        onMatchToOthers={onMatchToOthers}
                        onActiveIndexNameChange={this.handleActiveIndexNameChange}
                    />
                </div>
            </BasicPageLayout>
        );
    }

    matchRequestHasChanged() {
        const {state} = this.props;
        if (!state.lastRequest) return true;
        return !equal(state.nextRequest, state.lastRequest, {strict: true});
    }

    handleToggleMatchProfileExpanded = (forcedDisplayed) => {
        const {state} = this.props;
        const displayed = forcedDisplayed !== undefined ? forcedDisplayed : !state.ui.sections[Sections.MATCH_PROFILE];

        this.updateSections({
            [Sections.MATCH_PROFILE]: displayed,
            [Sections.RESULTS]: !displayed,
        });
    };

    handleToggleResultsExpanded = theDisplayed => {
        const {state} = this.props;
        const displayed =
            theDisplayed !== undefined ? theDisplayed : !state.ui.sections[Sections.RESULTS];

        this.updateSections({
            [Sections.MATCH_PROFILE]: !displayed,
            [Sections.RESULTS]: displayed,
        });
    };

    handleActiveIndexNameChange = activeIndexName => {
        this.setState({activeIndexName});
    };
}

export const Sections = {
    MATCH_PROFILE: "matchProfile",
    RESULTS: "results",
};
