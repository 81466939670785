import ProfileType from "~/enums/ProfileType";

/**
 * Not exactly like API response, but preprocessed for selection indication in the UI.
 *
 * MUST remain immutable, the UI refresh logic depends on this.
 */
export default class BackendSelection {
    candidatesForJob = {};
    candidatesForCompany = {};
    jobsForCandidate = {};
    companiesForCandidate = {};
    derived = {};

    externalSelectionIdByCandidate = {};
    externalSelectionIdByJob = {};
    externalSelectionIdByCompany = {};

    static fromApi(data) {
        const result = new BackendSelection();
        result.candidatesForJob = BackendSelection.convertApiArrayToObject(
            "candidatesForJob",
            data.candidatesForJob
        );
        result.candidatesForCompany = BackendSelection.convertApiArrayToObject(
            "candidatesForCompany",
            data.candidatesForCompany
        );
        result.jobsForCandidate = BackendSelection.convertApiArrayToObject(
            "jobsForCandidate",
            data.jobsForCandidate
        );
        result.companiesForCandidate = BackendSelection.convertApiArrayToObject(
            "companiesForCandidate",
            data.companiesForCandidate
        );
        result.derived = BackendSelection.convertDerivedSelection(data.derived);
        result.externalSelectionIdByCandidate = data.externalSelectionIdByCandidate || {};
        result.externalSelectionIdByJob = data.externalSelectionIdByJob || {};
        result.externalSelectionIdByCompany = data.externalSelectionIdByCompany || {};
        return result;
    }

    static merge(selectionA, selectionB) {
        const result = new BackendSelection();
        result.candidatesForJob = {...selectionA.candidatesForJob, ...selectionB.candidatesForJob};
        result.candidatesForCompany = {...selectionA.candidatesForCompany, ...selectionB.candidatesForCompany};
        result.jobsForCandidate = {...selectionA.jobsForCandidate, ...selectionB.jobsForCandidate};
        result.companiesForCandidate = {...selectionA.companiesForCandidate, ...selectionB.companiesForCandidate};
        result.derived = {...selectionA.derived, ...selectionB.derived};
        result.externalSelectionIdByCandidate = {...selectionA.externalSelectionIdByCandidate, ...selectionB.externalSelectionIdByCandidate};
        result.externalSelectionIdByJob = {...selectionA.externalSelectionIdByJob, ...selectionB.externalSelectionIdByJob};
        result.externalSelectionIdByCompany = {...selectionA.externalSelectionIdByCompany, ...selectionB.externalSelectionIdByCompany};
        return result;
    }

    static empty() {
        return new BackendSelection();
    }

    /**
     * Converts an array of IDs received from the API to an object with the IDs as keys and `true` as values. If the
     * received data is undefined, returns undefined.
     *
     * @param name name of the array, used for an error message
     * @param arrayData an array of IDs
     * @returns {object} conversion result
     */
    static convertApiArrayToObject(name, arrayData) {
        if (arrayData === undefined) {
            return {};
        } else if (!Array.isArray(arrayData)) {
            console.error(`Expected backend selection property ${name} to be an array`, arrayData);
            return {};
        }

        return arrayData.reduce((result, id) => {
            result[id] = true;
            return result;
        }, {});
    }

    static convertDerivedSelection(derived) {
        const result = {};

        for (const key in derived) {
            result[key] = BackendSelection.fromApi(derived[key]);
        }

        return result;
    }

    getSelectionType(entityId, companyId) {
        if (this.candidatesForJob && this.candidatesForJob[entityId] === true) {
            return "candidatesForJob";
        } else if (this.jobsForCandidate && this.jobsForCandidate[entityId] === true) {
            return "jobsForCandidate";
        } else if (this.candidatesForCompany && this.candidatesForCompany[entityId] === true) {
            return "candidatesForCompany";
        } else if (this.companiesForCandidate && this.companiesForCandidate[companyId] === true) {
            return "companiesForCandidate";
        } else {
            return undefined;
        }
    }

    isSelected(entityId) {
        if (this.candidatesForJob && this.candidatesForJob[entityId] === true) {
            return true;
        } else if (this.jobsForCandidate && this.jobsForCandidate[entityId] === true) {
            return true;
        } else {
            return false;
        }
    }

    getExternalDetails(entityId, companyId) {
        if (this.candidatesForJob && this.candidatesForJob[entityId] === true) {
            return {externalId: this.externalSelectionIdByCandidate[entityId]};
        } else if (this.jobsForCandidate && this.jobsForCandidate[entityId] === true) {
            return {externalId: this.externalSelectionIdByJob[entityId]};
        } else if (this.candidatesForCompany && this.candidatesForCompany[entityId] === true) {
            return {externalId: this.externalSelectionIdByCandidate[entityId]};
        } else if (this.companiesForCandidate && this.companiesForCandidate[companyId] === true) {
            return {externalId: this.externalSelectionIdByCompany[companyId]};
        } else {
            return undefined;
        }
    }

    getExcludedIds(resultType) {
        if (resultType === ProfileType.CANDIDATE) {
            return Object.keys(this.candidatesForJob);
        } else if (resultType === ProfileType.JOB) {
            return Object.keys(this.jobsForCandidate);
        } else {
            return [];
        }
    }

    addToExistingSelection(resultType, selection) {
        const result = Object.assign(new BackendSelection(), this);

        if (resultType === ProfileType.CANDIDATE) {
            result.candidatesForJob = {...result.candidatesForJob};
            selection.forEach(id => result.candidatesForJob[id] = true);
        } else if (resultType === ProfileType.JOB) {
            result.jobsForCandidate = {...result.jobsForCandidate};
            selection.forEach(id => result.jobsForCandidate[id] = true);
        }

        return result;
    }

    removeExistingSelectionFrom(ids) {
        return ids.filter(id => !this.candidatesForJob[id] && !this.jobsForCandidate[id]);
    }
}
