import React from "react";
import PropTypes from "prop-types";
import config from "~/config";
import MatchingPage from "~/components/MatchingPage";
import JobToCandidatesBreadcrumbs from "~/containers/components/JobToCandidatesBreadcrumbs";
import JobPropType from "~/prop-types/job";
import {toBaseMatchingConfiguration} from "~/util/matching-config";
import ProfileType from "~/enums/ProfileType";

export default class JobToCandidatesMatch extends React.PureComponent {
    static MATCHING_CONTEXT_ID = "job-to-candidates";

    static propTypes = {
        job: JobPropType.isRequired,
        jobParseState: PropTypes.string.isRequired,
        configuration: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        language: PropTypes.string.isRequired,
        updateLanguage: PropTypes.func.isRequired,
        newMatch: PropTypes.func.isRequired,
        updateMatchForIndex: PropTypes.func.isRequired,
        requestPage: PropTypes.func.isRequired,
        makeSelection: PropTypes.func.isRequired,
        setMatchSourceEntity: PropTypes.func.isRequired,
        setQuery: PropTypes.func.isRequired,
        setNextRequest: PropTypes.func.isRequired,
        setIndexNextRequest: PropTypes.func.isRequired,
        clearNextRequest: PropTypes.func.isRequired,
        setSelection: PropTypes.func.isRequired,
        downloadMatchResults: PropTypes.func.isRequired,
        setSortMode: PropTypes.func.isRequired,
        setSelectionStatus: PropTypes.func.isRequired,
        selectAllFromApi: PropTypes.func.isRequired,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        // TODO: This also needs a better solution
        if (prevProps.job !== this.props.job) {
            this.props.setNextRequest({
                ...this.props.state.nextRequest,
                matchProfile: this.props.job.matchProfile,
            });

            this.props.newMatch();
        }
    }

    render() {
        const {
            jobParseState,
            configuration,
            state,
            language,
            updateLanguage,
            newMatch,
            updateMatchForIndex,
            requestPage,
            makeSelection,
            setMatchSourceEntity,
            setQuery,
            setMatchingStrategy,
            setNextRequest,
            setIndexNextRequest,
            clearNextRequest,
            resetNextRequest,
            setSelection,
            downloadMatchResults,
            setSortMode,
            setSelectionStatus,
            updateUiState,
            selectAllFromApi,
        } = this.props;

        return (
            <MatchingPage
                additionalMenuContent={<JobToCandidatesBreadcrumbs />}
                isLoading={jobParseState !== config("enums.apiCallState.idle")}
                configuration={configuration}
                state={state}
                language={language}
                newMatch={newMatch}
                updateMatchForIndex={updateMatchForIndex}
                requestPage={requestPage}
                makeSelection={makeSelection}
                selectAllFromApi={selectAllFromApi}
                onMatchSourceEntityChange={setMatchSourceEntity}
                onLanguageChange={updateLanguage}
                onQueryChange={setQuery}
                onMatchingStrategyChange={setMatchingStrategy}
                onNextRequestChange={setNextRequest}
                onIndexNextRequestChange={setIndexNextRequest}
                onClearNextRequest={clearNextRequest}
                onResetNextRequest={resetNextRequest}
                onSelectionChange={setSelection}
                onDownload={downloadMatchResults}
                onSortModeChange={setSortMode}
                onSelectionStatusChange={setSelectionStatus}
                onUiStateUpdate={updateUiState}
            />
        );
    }

    static getConfiguration() {
        return () => {
            return toBaseMatchingConfiguration("features.jobToCandidates", ProfileType.CANDIDATE);
        };
    }
}
