import React from "react";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import c from "classnames";
import styles from "./job-list.module.scss";
import {FormattedMessage} from "react-intl";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import MatchDetailsDialog from "~/components/MatchDetailsDialog/MatchDetailsDialog";
import {getNameForJobMatch} from "~/components/JobMatchList/JobMatchList";
import config from "~/config";

export class Job extends React.PureComponent {
    static propTypes = {
        indexName: PropTypes.string.isRequired,
        job: PropTypes.shape({
            id: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            showingDetails: false,
        };
    }

    render() {
        return (
            <tr>
                {this.renderJobFunctions()}
                {this.renderHardSkills()}
                {this.renderLocations()}
                <td className={styles.actionsColumn}>
                    <button
                        className={c("btn", "btn-sm", "btn-info")}
                        onClick={this.handleDetailsClick}
                    >
                        <FormattedMessage id="match.details" />
                    </button>

                    <Dropdown
                        as={ButtonGroup}
                        size="sm"
                        className="ml-1"
                        onClick={this.handleMainClick}
                    >
                        <Button size="sm" variant="success" onClick={this.handleMatchCandidates}>
                            <FormattedMessage id="match.matchCandidates" />
                        </Button>

                        <Dropdown.Toggle split variant="success" id="dropdown-split-basic" />

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={this.handleMatchJobs}>
                                <FormattedMessage id="match.matchJobs" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {this.renderDetailsDialog()}
                </td>
            </tr>
        );
    }

    renderJobFunctions() {
        const {job} = this.props;
        const jobFunctions = job.matchProfile.aspects.jobFunctions.value.map(v => v.label);
        return <td className={styles.textColumn}>{jobFunctions.join(" / ") || job.title}</td>;
    }

    renderHardSkills() {
        const {job} = this.props;
        const hardSkills = job.matchProfile.aspects.hardSkills.value.map(v => v.label);
        return <td className={styles.textColumn}>{hardSkills.join(", ")}</td>;
    }

    renderLocations() {
        const {job} = this.props;
        const locations = [];

        for (const location of job.matchProfile.aspects.locations.value) {
            locations.push(location.label);
        }

        return <td>{locations.join(", ")}</td>;
    }

    renderDetailsDialog() {
        const {job} = this.props;
        const {showingDetails} = this.state;

        if (!showingDetails) {
            return null;
        }

        const match = {job, details: {}};

        return (
            <MatchDetailsDialog
                match={match}
                extraPropertiesOrganization={config("features.profileToJobs.extraProperties", {})}
                onHide={this.handleHideDetailsDialog}
                title={getNameForJobMatch(match)}
                hideForwardColumn={true}
                reverseLabel=""
            />
        );
    }

    handleDetailsClick = () => {
        this.setState({showingDetails: true});
    };

    handleHideDetailsDialog = () => {
        this.setState({showingDetails: false});
    };

    handleMatchCandidates = e => {
        const {indexName, job, history} = this.props;
        history.push(`/profile-to-candidates/job/${indexName}/${job.id}`);
    };

    handleMatchJobs = e => {
        const {indexName, job, history} = this.props;
        history.push(`/profile-to-jobs/job/${indexName}/${job.id}`);
    };
}

export default withRouter(Job);
