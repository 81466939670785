import React from "react";
import PropTypes from "prop-types";
import c from "classnames";
import MatchProfilePropType from "~/prop-types/match-profile";
import styles from "./styles.module.scss";
import MatchProfileEditor from "~/components/MatchProfileEditor";
import Button from "react-bootstrap/Button";
import {FormattedMessage} from "react-intl";
import MatchBlock from "~/containers/components/MatchBlock";
import Filters from "~/components/MatchingPage/Filters";
import config from "~/config";

export default class MatchProfile extends React.PureComponent {
    static propTypes = {
        configuration: PropTypes.object.isRequired,
        state: PropTypes.object.isRequired,
        matchProfile: MatchProfilePropType.isRequired,
        indexName: PropTypes.string.isRequired,
        activeIndexName: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
        matchRequestHasChanged: PropTypes.bool.isRequired,
        expanded: PropTypes.bool.isRequired,
        withMatchBlock: PropTypes.bool.isRequired,
        showResetButton: PropTypes.bool.isRequired,
        showSuggestions: PropTypes.bool.isRequired,
        onToggleExpanded: PropTypes.func.isRequired,
        onMatchProfileChange: PropTypes.func.isRequired,
        onMatch: PropTypes.func.isRequired,
        onClear: PropTypes.func.isRequired,
        onReset: PropTypes.func,
        onFiltersChange: PropTypes.func.isRequired,
        onLanguageChange: PropTypes.func.isRequired,
        onCustomFiltersPredicateChange: PropTypes.func.isRequired,
        matchProfileEditorRef: PropTypes.any,
        onActiveIndexNameChange: PropTypes.func.isRequired,
    };

    render() {
        const {
            state,
            expanded,
            withMatchBlock,
            language,
            matchRequestHasChanged,
            showResetButton,
            onLanguageChange,
            onMatch,
        } = this.props;

        const matchTutorialVideo =
            config("ui.matchTutorialVideosEnabled") &&
            config(`ui.matchTutorialVideos.${config("ui.locale")}`, undefined) !== undefined;

        return (
            <div className={c(styles.main, {expanded})}>
                <div
                    className={styles.titleBar}
                    style={{cursor: "pointer"}}
                    onClick={this.handleToggleExpanded}
                >
                    <div className={styles.profileLabel}>
                        <i className={c("fas", expanded ? "fa-caret-down" : "fa-caret-right")} />
                        <span className="text-nowrap">
                            <FormattedMessage id="match.refineProfile" />
                        </span>
                        {state.matchSourceEntity && state.matchSourceEntity.displayName && (
                            <span className={c("ml-1", styles.ellipsis)}>
                                ({state.matchSourceEntity.displayName})
                            </span>
                        )}
                    </div>
                    <div className="text-nowrap ml-2">
                        {matchTutorialVideo && (
                            <Button
                                className="ml-2"
                                size="sm"
                                variant="info"
                                onClick={this.handleShowTutorial}
                            >
                                <i className="fas fa-info-circle" />{" "}
                                <FormattedMessage id="action.tutorial" />
                            </Button>
                        )}
                        <Button
                            className="ml-2"
                            size="sm"
                            variant="secondary"
                            onClick={this.handleClear}
                        >
                            <i className="fas fa-ban" /> <FormattedMessage id="action.clear" />
                        </Button>
                        {showResetButton && (
                            <Button
                                className="ml-2"
                                size="sm"
                                variant="light"
                                onClick={this.handleReset}
                            >
                                <i className="fas fa-undo" /> <FormattedMessage id="action.reset" />
                            </Button>
                        )}
                    </div>
                    {withMatchBlock && (
                        <MatchBlock
                            className={styles.matchBlock}
                            language={language}
                            matchRequestHasChanged={matchRequestHasChanged}
                            matchActionLabel={
                                state.matchSourceEntity ? "action.match" : "action.search"
                            }
                            onLanguageChange={onLanguageChange}
                            onMatch={onMatch}
                        />
                    )}
                </div>
                {this.renderContent()}
            </div>
        );
    }

    renderContent() {
        const {
            configuration,
            state,
            expanded,
            activeIndexName,
            indexName,
            language,
            matchProfile,
            showSuggestions,
            onFiltersChange,
            onMatchProfileChange,
            matchProfileEditorRef,
            onCustomFiltersPredicateChange,
            onActiveIndexNameChange,
        } = this.props;

        return (
            <div className={c(styles.content, {expanded})}>
                <MatchProfileEditor
                    profile={matchProfile}
                    index={indexName} // TODO
                    language={language}
                    isEditing={true}
                    isHorizontal={true}
                    showSuggestions={showSuggestions}
                    onChange={onMatchProfileChange}
                    ref={matchProfileEditorRef}
                />
                <Filters
                    configuration={configuration}
                    state={state}
                    activeIndexName={activeIndexName}
                    onCustomFiltersPredicateChange={onCustomFiltersPredicateChange}
                    onFiltersChange={onFiltersChange}
                    onActiveIndexNameChange={onActiveIndexNameChange}
                />
            </div>
        );
    }

    handleClear = e => {
        e.stopPropagation();
        this.props.onClear();
        this.props.onToggleExpanded(true);
    };

    handleReset = e => {
        e.stopPropagation();
        this.props.onReset();
        this.props.onToggleExpanded(true);
    };

    handleToggleExpanded = () => {
        this.props.onToggleExpanded();
    }

    handleShowTutorial = e => {
        e.stopPropagation();

        const matchTutorialVideo = config(
            `ui.matchTutorialVideos.${config("ui.locale")}`,
            undefined
        );

        if (matchTutorialVideo) {
            window.open(matchTutorialVideo, "_blank");
        }
    };
}
