import React from "react";
import PropTypes from "prop-types";
import Table from "react-bootstrap/Table";
import {FormattedMessage} from "react-intl";
import * as immutable from "object-path-immutable";
import c from "classnames";
import config from "~/config";
import {EnabledAspectsBlackWhiteList} from "~/util/enabled-aspects";
import AspectRow from "./AspectRow";
import WorkExperiencesRow from "./WorkExperiencesRow";
import EducationsRow from "./EducationsRow";
import styles from "./styles.module.scss";
import ProgressBar from "~/components/ProgressBar";

const Sections = {
    ASPECTS: "aspects",
    EXTRA_PROPERTIES: "extraProperties",
    WORK_EXPERIENCES: "workExperiences",
    EDUCATIONS: "educations",
}

export default class MatchComparisonTable extends React.PureComponent {
    static propTypes = {
        matches: PropTypes.array, // TODO: More specific
        detailsTitleProperty: PropTypes.string,
        comparisonSubtitleProperty: PropTypes.string,
        reverseLabel: PropTypes.string.isRequired,
        reverseHelp: PropTypes.string.isRequired,
        sections: PropTypes.arrayOf(PropTypes.string),
    };

    static defaultProps = {
        sections: [Sections.ASPECTS, Sections.EXTRA_PROPERTIES],
    }

    render() {
        const {sections} = this.props;

        return (
            <Table size="sm" className={styles.table}>
                <thead>{this.renderHeader()}</thead>
                <tbody>
                    {sections.includes(Sections.ASPECTS) && this.renderAspects()}
                    {sections.includes(Sections.EXTRA_PROPERTIES) && this.renderExtraProperties()}
                    {sections.includes(Sections.WORK_EXPERIENCES) && this.renderWorkExperiences()}
                    {sections.includes(Sections.EDUCATIONS) && this.renderEducations()}
                </tbody>
            </Table>
        );
    }

    renderHeader() {
        const {matches} = this.props;

        return (
            <tr>
                <th className={c(styles.inlineHeader)}>
                    <FormattedMessage id="matchQuality.aspectColumn" />
                </th>
                {matches.map((match, index) => (
                    <th key={index} className={c(styles.matchColumn, styles.stickyHeader)}>
                        <div>
                            <span className={styles.headerSubtitle}>{this.getSubtitle(match)}</span>
                        </div>
                        <div className={styles.matchHeader}>
                            <span>{this.getTitle(match)}</span>
                            <ProgressBar
                                className={styles.itemProgress}
                                variant="success"
                                now={match.score}
                                min={0}
                                max={1}
                            />
                        </div>
                    </th>
                ))}
            </tr>
        );
    }

    getTitle(match) {
        const {detailsTitleProperty} = this.props;
        return detailsTitleProperty ? immutable.get(match.document, detailsTitleProperty) : undefined;
    }

    getSubtitle(match) {
        const {comparisonSubtitleProperty} = this.props;
        return comparisonSubtitleProperty ? immutable.get(match.document, comparisonSubtitleProperty) : undefined;
    }

    renderAspects() {
        const {matches, reverseLabel, reverseHelp} = this.props;

        return config("ui.aspectOrder")
            .filter(aspect => EnabledAspectsBlackWhiteList.allows(aspect))
            .map((aspect, index) => (
                <AspectRow
                    key={aspect}
                    name={aspect}
                    reverseLabel={reverseLabel}
                    reverseHelp={reverseHelp}
                    isFirstRow={index === 0}
                    matches={matches}
                />
            ));
    }

    renderExtraProperties() {
        return null;
        // const {matches} = this.props;
        //
        // return (
        //     <React.Fragment>
        //         <tr>
        //             <td className={styles.inlineHeader}>
        //                 <FormattedMessage id="detailsModal.extraProperties" />
        //             </td>
        //             <td colSpan={matches.length} />
        //         </tr>
        //     </React.Fragment>
        // );
    }

    renderWorkExperiences() {
        const {matches} = this.props;

        return (
            <React.Fragment>
                <tr>
                    <td className={styles.inlineHeader}>
                        <FormattedMessage id="detailsModal.workExperiences" />
                    </td>
                    <td colSpan={matches.length} />
                </tr>
                <WorkExperiencesRow matches={matches} />
            </React.Fragment>
        );
    }

    renderEducations() {
        const {matches} = this.props;

        return (
            <React.Fragment>
                <tr>
                    <td className={styles.inlineHeader}>
                        <FormattedMessage id="detailsModal.educations" />
                    </td>
                    <td colSpan={matches.length} />
                </tr>
                <EducationsRow matches={matches} />
            </React.Fragment>
        );
    }
}

MatchComparisonTable.Sections = Sections;